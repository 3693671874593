@import 'styles/common';

:root {
  --font-default: 16px;
  --font-family-heading-default: muller, arial, sans-serif;
  --font-family-base-default: 'Open Sans', sans-serif;

  // can be changed inside app
  --font-base: var(--font-default);
  --font-family-heading: var(--font-family-heading-default);
  --font-family-base: var(--font-family-base-default);
  --letter-spacing: 0;
  --form-border: 1px solid #d4d6d7;
}

#__next {
  height: 100%;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  color: $content;
  scrollbar-color: $scroll transparent;
  scrollbar-width: 6px;
  overflow-y: overlay;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $scroll;
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  margin: 10px;
}

.noScroll {
  @include media-breakpoint-down(md) {
    margin-right: 0;
    overflow: hidden;
  }
}

a {
  text-decoration: none;
  color: inherit;
  letter-spacing: var(--letter-spacing);
}

a.white {
  text-decoration: underline;
  color: $white;

  &:hover {
    color: $white;
    opacity: .7;
    transition: opacity .3s ease-in-out;
  }
}

* {
  box-sizing: border-box;
}

label {
  font-weight: 600;
  font-size: calc(var(--font-base) * .875);
  font-family: var(--font-family-base);
  letter-spacing: var(--letter-spacing);
}

h1 {
  font-weight: normal;
  font-size: calc(var(--font-base) * 2.5);
  line-height: 1.2;
  font-family: var(--font-family-heading);
  letter-spacing: var(--letter-spacing);

  @include mq(md) {
    margin-bottom: 24px;
    font-size: calc(var(--font-base) * 2);
  }

  @include mq(sm) {
    margin-bottom: 24px;
    font-size: calc(var(--font-base) * 1.5);
  }
}

h2 {
  font-weight: normal;
  font-size: calc(var(--font-base) * 2);
  line-height: 1.2;
  font-family: var(--font-family-heading);
  letter-spacing: var(--letter-spacing);

  @include mq(md) {
    font-size: calc(var(--font-base) * 1.5);
  }
}

h3 {
  font-weight: normal;
  font-size: calc(var(--font-base) * 1.5);
  font-family: var(--font-family-heading);
  letter-spacing: var(--letter-spacing);

  @include mq(md) {
    font-size: calc(var(--font-base) * 1.25);
  }
}

h4,
h5 {
  font-weight: bold;
  font-size: calc(var(--font-base) * 1.25);
  font-family: var(--font-family-heading);
  letter-spacing: var(--letter-spacing);
}

p {
  font-weight: normal;
  font-size: var(--font-base);
  font-family: var(--font-family-base);
  letter-spacing: var(--letter-spacing);

  @include mq(xl) {
    font-size: calc(var(--font-base) * .875);
  }
}