@import "styles/common";

.tooltip {
    position: fixed;
    top: 50px;
    right: 4%;
    z-index: 9999;
    max-width: 280px;
    min-height: 100px;
    border: 1px solid;
    border-radius: 8px;
    opacity: 0;
    transition: .2s;

    &_big {
        max-width: 280px;
        min-height: 250px;
    }

    &_active {
        opacity: 1;
    }

    &_success {
        color: $gray-text;
        background-color: $success-background-color;
        border-color: $success-border-color;
    }

    &_error {
        color: $gray-text;
        background-color: $error-backgrounf-color;
        border-color: $error-border-color;
    }

    &__wrapper {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 120px;
        height: 100%;
        padding: 0 16px;
    }

    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }

    &__close > svg {
        width: 16px;
        height: 16px;
        fill: #2E3542;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    &__title {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        &_big {
            margin-top: 10px;
            font-size: 15px;
            line-height: 20px;
        }
    }

    &__text {
        font-size: 13px;
        line-height: 20px;
        
        &_big{
            margin-top: 10px;
            font-size: 14px;
            line-height: 23px;
        }
    }
}