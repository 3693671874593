$fonts-path: '../../public/fonts';
$font-family-main: 'Open Sans';
$font-family-accent: 'Muller';

@font-face {
  $font-name: 'MullerRegular';

  font-style: normal;
  font-weight: 400;
  font-family: $font-family-accent;
  src:
    local('#{$font-family-accent}, Regular'),
    url('#{$fonts-path}/#{$font-name}.woff2') format('woff2'),
    url('#{$fonts-path}/#{$font-name}.woff') format('woff'),
    url('#{$fonts-path}/#{$font-name}.ttf') format('truetype');
}

@font-face {
  $font-name: 'MullerBold';

  font-style: normal;
  font-weight: 700;
  font-family: $font-family-accent;
  src:
    local('#{$font-family-accent}, Bold'),
    url('#{$fonts-path}/#{$font-name}.woff2') format('woff2'),
    url('#{$fonts-path}/#{$font-name}.woff') format('woff'),
    url('#{$fonts-path}/#{$font-name}.ttf') format('truetype');
}

@font-face {
  $font-name: 'OpenSans-Regular';

  font-style: normal;
  font-weight: 400;
  font-family: 'Open Sans';
  src:
    local('#{$font-family-main}, Regular'),
    url('#{$fonts-path}/#{$font-name}.woff2') format('woff2'),
    url('#{$fonts-path}/#{$font-name}.woff') format('woff'),
    url('#{$fonts-path}/#{$font-name}.ttf') format('truetype');
}

@font-face {
  $font-name: 'opensanssemibold';

  font-style: normal;
  font-weight: 600;
  font-family: 'Open Sans';
  src:
    local('#{$font-family-main}, Semibold'),
    url('#{$fonts-path}/#{$font-name}.woff2') format('woff2'),
    url('#{$fonts-path}/#{$font-name}.woff') format('woff'),
    url('#{$fonts-path}/#{$font-name}.ttf') format('truetype');
}
