@import 'styles/common';

.form-group {
  margin-bottom: 24px;

  // input phone
  .react-tel-input {
    .form-control {
      height: 40px;
      font-size: calc(var(--font-base) * .875);
      border: var(--form-border);
      letter-spacing: var(--letter-spacing);

      &:disabled {
        background-color: #f4f4f4;
      }
    }

    .flag-dropdown {
      border: var(--form-border);

      &.invalid-number {
        border-color: $danger;
      }
    }

    .country-list {
      .search-box {
        margin-left: 0;
      }

      .search {
        padding-left: 8px;
      }
    }

    & + .invalid-feedback {
      display: block;
    }
  }

  // select form

  .dropdown {
    & + .invalid-feedback {
      display: block;
    }
  }

  .form__indicator-separator {
    display: none;
  }
}

.form-control {
  width: 100% !important;
  height: 40px;
  padding: 10px 16px;
  font-size: calc(var(--font-base) * .875);
  font-family: var(--font-family-base);
  border: var(--form-border);
  letter-spacing: var(--letter-spacing);

  &:focus {
    border-color: $input-focus-border-color;

    & + .flag-dropdown {
      border-color: $input-focus-border-color;
    }
  }

  &.invalid-number {
    background-color: $white;
    border-color: $danger;

    &:focus {
      background-color: $white;
      border-color: $danger;

      & + .flag-dropdown {
        border-color: $danger;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .form-control {
    width: 400px;
    font-size: 1rem;
  }
}

.form-check {
  padding-left: 0;
  font-family: var(--font-family-base);

  &-input[type='radio'] {
    position: absolute;
    left: -100%;
    z-index: -100;
    display: none;
    width: 0;
    height: 0;
    border: none;

    &:checked + .form-check-label {
      &::after {
        background: $graphite;
      }

      &::before {
        border-color: $graphite;
      }
    }

    & + .form-check-label {
      position: relative;
      display: inline-block;
      margin-bottom: 0;
      padding: calc(var(--font-base) * .25) 0 calc(var(--font-base) * .25) calc(var(--font-base) * 1.5);
      font-weight: 400;
      line-height: 24px;

      &::before,
      &::after {
        position: absolute;
        content: '';
        display: block;
        background: $white;
        border-radius: 50%;
      }

      &::before {
        top: calc(var(--font-base) * .5);
        left: 0;
        width: var(--font-base);
        height: var(--font-base);
        border: 1px solid $btn-outline;
        border-radius: 50%;
      }

      &::after {
        top: calc(var(--font-base) * .75);
        left: calc(var(--font-base) * .25);
        width: calc(var(--font-base) * .5);
        height: calc(var(--font-base) * .5);
        border-radius: 50%;
      }
    }
  }
}

.form-check-input[type='radio']:not(:disabled) + .form-check-label {
  &:hover {
    cursor: pointer;

    &::before {
      border-color: $graphite;
    }
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  padding-right: inherit;
  background-image: none;
}

.btn-sm {
  padding: 10.5px 24px;
}

.required-mark {
  color: $danger;
}

.dropdown-menu {
  min-width: 71px;
}

.react-datepicker {
  font-family: $font-family-base;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__day--selected {
  background-color: $primary;
}

.invalid-feedback {
  display: block;
  font-size: calc(var(--font-base) * .8);
  letter-spacing: calc(var(--letter-spacing) * .5);
}

.form-label {
  display: flex;
}
