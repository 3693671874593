@import "styles/common";

.button {
  display: inline-flex;
  align-items: center;
  padding: 11px 24px;
  background-color: transparent;
  border: 1px solid $graphite;
  border-radius: 0.3rem;
  cursor: pointer;

  svg {
    width: var(--font-base);
    fill: currentcolor;
  }

  &.btn-add {
    margin-left: 0 !important;
  }

  &[data-color="primary"] {
    color: $white;
    background-color: $graphite;
    border: none;
  }

  &[data-size="lg"] {
    justify-content: center;
    width: 100%;
  }

  &_rounded {
    border-radius: 90px !important;
  }

  &_unborder {
    text-decoration: underline;
    color: $primary;
    background-color: transparent;
    border: none;

    &:not(:disabled, .disabled):active {
      color: $primary;
      background-color: transparent;
    }
  }

  &_transform {
    svg {
      transform: rotate(180deg);
    }
  }

  &_disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @include media-breakpoint-down(lg) {
    margin-top: 10px;
  }
}

.left {
  margin-right: 12px;
}

.right {
  margin-left: 12px;

  @include media-breakpoint-down(lg) {
    margin-left: 12px !important;
  }
}

.outline-primary {
  width: 20px;
}
