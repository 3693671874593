@import 'styles/common';

.btn {
  display: block;
  padding: 11px 24px;
  border: 1px solid rgba($graphite, 1);
  transition: all .25s ease-in-out;
  overflow: auto;
  cursor: pointer;
  will-change: transform;
  background-size: 200% 100%;

  &:hover {
    background-position: -100% 0%;
  }

  &-primary {
    background-image: linear-gradient(to left, $modal-background 50%, $graphite 50%);

    &:hover {
      color: $graphite;

      svg {
        fill: $graphite;
      }
    }

    &:focus {
      border: 1px solid $graphite;
      outline: none;
      box-shadow: none;
    }

    &:not(:disabled, .disabled):active:focus,
    &:not(:disabled, .disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      color: $graphite;
      background-color: $graphite;
      outline: none;
      box-shadow: none;
    }

    svg {
      transition: fill .25s;
      fill: $white;
    }
  }

  &-outline-primary {
    background-image: linear-gradient(to left, $graphite 50%, $modal-background 50%);

    &:not(:disabled, .disabled):active:focus,
    &:not(:disabled, .disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      border: 1px solid $btn-outline;
      outline: none;
      box-shadow: none;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:hover {
      color: $white;

      svg {
        fill: $white;
      }
    }

    svg {
      transition: fill .25s;
      fill: $graphite;
    }
  }

  &-lg {
    font-size: var(--font-base);
    letter-spacing: var(--letter-spacing);
  }

  &-add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    font-weight: 600;
    font-size: calc(var(--font-base) * .875);
    font-family: var(--font-family-base);

    &:hover {
      background-color: $graphite;
    }

    &::after {
      content: none;
    }
  }

  &-light {
    padding: 0;
    font-size: calc(var(--font-base) * .875);
    line-height: 19px;
    color: $graphite;
    background: transparent;
    border: none;

    &:focus,
    &:hover,
    &:not(:disabled, .disabled):active,
    &:not(:disabled, .disabled).active,
    .show > &.dropdown-toggle,
    &:not(:disabled, .disabled):active:focus,
    &:not(:disabled, .disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      color: $graphite;
      background: transparent;
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

  &_underline {
    text-decoration-line: underline;
  }
}
