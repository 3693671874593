@import 'styles/common';

.header {
  position: relative;
  z-index: $z-index-header;
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  background: $graphite;
  border-bottom: 1px solid $gray-border;

  @include media-breakpoint-down(sm) {
    position: fixed;
  }

  @include media-breakpoint-down(xs) {
    height: 64px;
  }

  &_accessible {
    background: $white;
  }

  &__col_left {
    display: flex;
    align-items: center;
    padding-left: 16px;

    @include media-breakpoint-down(xs) {
      padding-left: 8px;
    }
  }

  &__col_right {
    padding-right: 16px;

    @include media-breakpoint-down(xs) {
      padding-right: 8px;
    }
  }

  &__nav {
    margin-right: $gutters * 2.5;
  }

  &__burger {
    width: 24px;
    height: 24px;
    margin: 0 16px 2px 0;
    fill: $secondary;
    cursor: pointer;
  }

  &__logo {
    display: flex;
    align-items: center;

    div {
      @include transition($transition-base);
    }

    &_link:hover {
      text-decoration: none;
    }

    &_text {
      position: relative;
      margin-left: 12px;
      padding-left: 12px;
      font-size: 20px;
      font-family: $font-family-heading;
      color: $dark-gray;
      letter-spacing: 0;

      &::after {
        position: absolute;
        top: 3px;
        left: 0;
        content: '';
        display: block;
        height: 20px;
        border-left: 1px solid $dark-gray;

        &_accessible {
          color: $content;
        }

        @include media-breakpoint-down(xs) {
          top: 4px;
          height: 12px;
        }

        @include transition($transition-base);
      }

      &_accessible {
        color: $content;
      }

      @include media-breakpoint-down(xs) {
        font-size: 14px;
      }

      @include transition($transition-base);
    }

    @include transition($transition-base);
  }
}

.modal {
  position: absolute;
  top: 70px;
  left: -100vw;
  width: 100vw;
  height: 100vh;
  background: $graphite;
  visibility: hidden;

  @include media-breakpoint-down(xs) {
    top: 64px;
  }

  &_active {
    top: 70px;
    left: 0;
    visibility: visible;

    @include media-breakpoint-down(xs) {
      top: 64px;
    }
  }

  @include transition($transition-base);

  &__navigation {
    margin-top: 24px;
  }

  &__tab {
    display: flex;
    align-items: center;
    width: calc(100% + 24px);
    height: 58px;
    margin: 0 -12px;
    padding: 12px;
    cursor: pointer;

    &_active {
      background: $gradient;
      border-radius: 8px;
    }

    &__text {
      margin: 0;
      font-size: 16px;
      font-family: $font-family-heading;
      background: $gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      &_active {
        margin: 0;
        font-size: 16px;
        font-family: $font-family-heading;
        color: $graphite-text;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    margin-bottom: 24px;
    cursor: pointer;
  }

  &__text {
    margin: 0;
    font-size: 16px;
    font-family: $font-family-heading;
    background: $gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
