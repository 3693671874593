@import 'styles/common';

.header {
  z-index: 20;
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  background: $white;
  border-bottom: 1px solid $gray-border;

  @include media-breakpoint-down(sm) {
    position: fixed;
  }
}

.accessible {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;

  &__col {
    display: flex;
    align-items: center;

    div {
      margin: 5px;
    }
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 10px;
    line-height: 1;
    cursor: pointer;
  }
}

.font {
  padding: 5px 10px;

  &:hover {
    color: $white;
    background: $content;
  }

  &:hover::after,
  &_active::after {
    @include accessible-header-icon($content, -5px);
  }

  &_m {
    font-size: var(--font-default);
  }

  &_l {
    font-size: calc(var(--font-default) * 1.5);
  }

  &_xl {
    font-size: calc(var(--font-default) * 2);
  }

  &_active {
    color: $white;
    background: $content;
  }
}

.spacing {
  &:hover {
    color: $white;
    background: $content;
  }

  &:hover::after,
  &_active::after {
    @include accessible-header-icon($content, -5px);
  }

  &_m {
    letter-spacing: 0;
  }

  &_l {
    padding-right: 6px;
    letter-spacing: 4px;
  }

  &_xl {
    padding-right: 2px;
    letter-spacing: 8px;
  }

  &_active {
    color: $white;
    background: $content;
  }
}

.family {
  &:hover {
    color: $white;
    background: $content;
  }

  &:hover::after,
  &_active::after {
    @include accessible-header-icon($content, -5px);
  }

  &_normal {
    font-family: $font-family-base;
  }

  &_arial {
    font-family: Arial, serif;
  }

  &_times {
    font-family: "Times New Roman", serif;
  }

  &_active {
    color: $white;
    background: $content;
  }
}

.color {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  padding-top: 5px;
  font-size: 24px;
  border: 1px solid $content;
  cursor: pointer;

  &:hover::after,
  &_active::after {
    @include accessible-header-icon(inherit, -6px);
  }

  &_white {
    color: $content;
    background: $white;
  }

  &_black {
    color: $white;
    background: $content;
  }

  &_blue {
    color: $accessible-blue-text;
    background: $accessible-blue-background;
  }
}

.images {
  width: 200px;
  cursor: pointer;
}
