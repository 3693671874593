@import 'styles/common';

.toolbar {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0 (-$gutters * .75);

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;

    &:hover {
      background: $gray-border;
      border-radius: 8px;
      cursor: pointer;

      svg {
        fill: $white;
      }
    }

    &_counter {
      position: absolute;
      top: 3px;
      right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      background: $red;
      border-radius: 50%;

      p {
        margin: 2px 0 0;
        font-weight: bold;
        font-size: 12px;
        font-family: $font-family-heading;
        color: $white;
      }
    }

    &_attention {
      position: relative;

      &::after {
        position: absolute;
        top: 2px;
        right: -1px;
        content: '';
        display: block;
        width: 9px;
        height: 9px;
        background-color: $additional-color-5;
        border: 1px solid $bg-dark;
        border-radius: 50%;
      }
    }

    &_default {
      margin-bottom: 2px;
      margin-left: 8px;
    }

    &_icon {
      width: var(--font-base);
      height: var(--font-base);
      fill: $additional-color-6;
      cursor: pointer;
    }

    &_img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
      cursor: pointer;
    }

    &_active {
      transition: fill .3s ease-in-out;
      fill: $graphite;
    }
  }

  &__theme {
    display: flex;
    align-items: center;
    color: $white;
    cursor: pointer;

    &:hover {
      color: $white;
      background: $gray-border;
      border-radius: 8px;

      svg {
        fill: $white;
      }
    }

    &_accessible {
      color: $content;
    }
  }

  &__icon {
    fill: $white;

    &_accessible {
      fill: $content;
    }
  }

  &__exit {
    width: 22px;
    height: 22px;
    margin-left: 5px;
  }

  &__favorite {
    width: 25px;
    height: 24px;
  }

  &__accessible {
    width: 24px;
    height: 24px;
    margin-top: 2px;
  }

  &__tools {
    &_wrap {
      padding: 16px 24px;
      text-align: left;
    }
  }

  &__lang {
    font-family: $font-family-heading;
    text-transform: uppercase;
    color: $white;
    background: none;
    border: none;
  }
}

.action {
  &__heading {
    font-weight: 600;
    font-size: calc(var(--font-base) * .875);
    line-height: 19px;
    text-align: center;
  }
}

.tooltip {
  width: 286px;
  padding: 8px 4px;
  font-size: calc(var(--font-base) * .75);
  line-height: var(--font-base);
}
