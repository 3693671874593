@import 'styles/common';

.footer {
  padding-top: 40px;
  font-size: 14px;
  color: $gray-footer;
  background-color: $graphite;
  letter-spacing: 0;

  a {
    letter-spacing: 0;
  }

  &_accessible {
    color: $content;
    background: $white;
  }

  &__grid {
    &_left {
      @include media-breakpoint-down(lg) {
        padding-right: 20px;
      }

      @include media-breakpoint-down(md) {
        padding-right: 16px;
      }

      @include media-breakpoint-down(xs) {
        margin-bottom: 20px;
      }
    }

    &_middle {
      padding-left: 65px;

      &_left {
        padding: 0 24px 0 0;

        @include media-breakpoint-down(lg) {
          padding-right: 8px;
        }

        @include media-breakpoint-down(md) {
          padding: 0;
        }
      }

      &_right {
        padding-left: 16px;

        @include media-breakpoint-down(lg) {
          padding: 0 0 0 32px;
        }

        @include media-breakpoint-down(md) {
          padding: 0;
        }
      }

      @include media-breakpoint-down(lg) {
        padding-left: 32px;
      }

      @include media-breakpoint-down(md) {
        padding-left: 16px;
      }

      @include media-breakpoint-down(xs) {
        margin-bottom: 20px;
      }
    }

    &_right {
      display: flex;
      flex-direction: column;
      padding-left: 90px;

      @include media-breakpoint-down(lg) {
        padding-left: 20px;
      }
    }
  }

  &__row {
    align-items: flex-start;
    padding: 0 8px;

    @include media-breakpoint-down(xs) {
      padding: 0;
    }
  }

  &__itmoLogo {
    margin-bottom: 5px;

    &:hover {
      opacity: .5;

      @include transition($transition-base);
    }

    &_accessible {
      filter: invert(1) grayscale(1);
    }
  }

  &__heading {
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 20px;
    line-height: 1;
    font-family: $font-family-heading;
    color: $white;
    letter-spacing: 0;

    &_accessible {
      color: $content;
    }
  }

  &__item {
    margin-bottom: 20px;

    p {
      margin: 0;
      font-size: 14px;
      line-height: 20px;
      font-family: $font-family-base;
      letter-spacing: 0;
    }

    @include media-breakpoint-down(xs) {
      margin-bottom: 12px;
    }
  }

  &__copyright {
    margin-bottom: 3px;
    font-size: 12px;
    letter-spacing: 0;

    &_left {
      margin-top: 28px;
      padding-right: 16px;
      
      @include media-breakpoint-down(md) {
        margin-top: 0;
      }

      @include media-breakpoint-down(xs) {
        padding: 0;
        text-align: center;
      }
    }

    &_right {
      margin-top: 28px;
      padding-left: 16px;
      text-align: right;

      @include media-breakpoint-down(md) {
        margin-top: 0;
        text-align: unset;
      }

      @include media-breakpoint-down(xs) {
        padding: 0;
        text-align: center;
      }
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 8px;
    }
  }

  &_top {
    &_accessible {
      padding-top: 18px;
      border-top: 1px solid $gray-border;
    }

    @include media-breakpoint-down(xs) {
      text-align: center;
    }
  }

  &_bottom {
    padding: 18px 0 24px;
    border-top: 1px solid $gray-border;
  }

  &__soc {
    display: flex;
    margin-bottom: 24px;

    svg {
      width: 18px;
      height: 18px;
      fill: $graphite;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin-right: 8px;
      background: $white;
      border-radius: 100%;

      &:hover {
        opacity: .5;

        @include transition($transition-base);
      }
    }

    &__link {
      margin-bottom: 3px;

      &:hover {
        text-decoration: none;
      }
    }

    @include media-breakpoint-down(xs) {
      justify-content: center;
    }
  }

  &__buttonBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 48px;
    font-size: 14px;
    border-color: $white;
    letter-spacing: 0;

    button {
      max-width: 200px;
      margin: 0 !important;
    }

    @include media-breakpoint-down(xs) {
      align-items: center;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 40px;
    }

    @include transition($transition-base);
  }
}
