@import 'styles/common';

.logo {
  font-size: 24px;
  font-family: $font-family-heading;
  background: $gradient;
  letter-spacing: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @include media-breakpoint-down(xs) {
    font-size: 18px;
    background: $gradient-linear;
    -webkit-background-clip: text;
  }

  &:hover {
    text-decoration: none;
    color: inherit;
    opacity: .5;
  }

  &_simple {
    font-size: 24px;
    font-family: $font-family-heading;
    letter-spacing: 0;
  }

  &__first {
    text-transform: uppercase;
    letter-spacing: 0;
  }

  &__second {
    font-weight: bold;
  }

  &__dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-right: 4px;
    margin-left: 2px;
    background: $gradient;
    border-radius: 50%;

    @include media-breakpoint-down(xs) {
      width: 4px;
      height: 4px;
    }

    &_simple {
      width: 6px;
      height: 6px;
      background: $danger;
    }

    &_accessible {
      background: $content;
    }
  }

  @include transition($transition-base);
}
