.skeleton {
    width: 100%;
    height: 30px;
    background-position: left -40px top 0;
    background-repeat: no-repeat;
    background-color: #e2e5e7;
    background-image:
        linear-gradient(90deg,
            rgba(#fff, 0),
            rgba(#fff, 0.5),
            rgba(#fff, 0));
    border-radius: 4px;
    background-size: 40px 100%;
    animation: shine 1s ease infinite;

    &.rounded {
        width: 50px;
        height: 50px;
        border-radius: 100%;
    }
}

@keyframes shine {
    to {
        background-position: right -40px top 0;
    }
}