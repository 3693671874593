@import 'styles/common';

.button {
    display: inline-flex;
    align-items: center;
    padding-right: 24px;
    padding-left: 24px;
    overflow: hidden;

    svg {
        width: var(--font-base);
    }

    &_rounded {
        border-radius: 90px;
    }

    &_unborder {
        text-decoration: underline;
        color: $primary;
        background-color: transparent;
        border: none;

        &:not(:disabled, .disabled):active {
            color: $primary;
            background-color: transparent;
        }

        &:hover,
        &:active,
        &:visited,
        &:focus {
            color: $primary;
            background-color: transparent;
            border: none !important;

            svg {
                fill: $graphite;
            }
        }
    }

    &_transform {
        svg {
            transform: rotate(180deg);
        }
    }

    &_disabled {
        pointer-events: none;
    }

    @include media-breakpoint-down(lg) {
        margin-top: 10px;
        margin-left: 10px !important;
    }
}

.left {
    margin-right: 12px;
}

.right {
    margin-left: 12px;

    @include media-breakpoint-down(lg) {
        margin-left: 12px !important;
    }
}

.outline-primary {
    width: 20px;
}