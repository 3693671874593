.wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.buttonSkeleton {
    max-width: 100px;
    height: 40px;
}

.listBlock {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
}

.listBlock__title {
    display: grid;
    grid-template-columns: 1fr 6fr;
    grid-gap: 8px;
}

.listBlock__item {
    max-width: calc(100% - 20px);
    height: 24px;
    margin-left: auto;
}