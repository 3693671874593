@import 'styles/common';

.ErrorPage {
  margin-top: 10em;
  margin-bottom: calc(10em + var(--padding));

  --padding: 40px;

  @include media-breakpoint-down(xs) {
    margin-top: 0;
    margin-bottom: var(--padding);
  }

  &__number {
    font-size: 64px;
    line-height: 1.2em;
    font-family: var(--font-family-heading-default);
  }

  &__title {
    margin-top: .75em;
    margin-bottom: 0;
    font-size: 24px;
    color: #222;
  }

  &__paragraph {
    margin-top: 1em;
    margin-bottom: 0;
    color: $gray-text;
  }

  &__btn {
    margin-top: 1.5em;
    border-radius: 90px;
  }
}
