@import '../common';

.tooltip-custom {
  margin-left: 4px;
  font-weight: 600;

  &.show {
    opacity: 1;
  }

  .arrow {
    &::before {
      border-bottom-color: $graphite;
    }
  }

  &.bs-tooltip-bottom {
    .arrow {
      &::before {
        border-bottom-color: $graphite;
      }
    }
  }

  &.bs-tooltip-top {
    .arrow {
      &::before {
        border-top-color: $graphite;
      }
    }
  }

  .tooltip-inner {
    max-width: 100%;
    background-color: $graphite;
  }
}
