@import "styles/common";

.select {
  position: relative;
  display: block;

  &__label {
    user-select: none;

    span {
      color: $danger;
    }
  }

  &__input {
    width: 100%;
    padding: 0.5rem 1rem;
    padding-right: 3rem;
    font-size: calc(var(--font-base) * 0.875);
    font-family: var(--font-family-base);
    text-align: left;
    color: $graphite-text;
    background-color: $white;
    border: 1px solid $secondary;
    border-radius: 0.25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &::placeholder {
      color: $gray-placeholder;
    }

    &_error {
      border-color: $danger;
    }
  }

  &__icon {
    position: absolute;
    right: 1rem;
    bottom: calc(20px - 0.5em);
    width: 1rem;
    height: 1rem;
    background-image: url("/images/symbols/arrow_down.svg");
    pointer-events: none;

    > path {
      fill: $graphite;
    }
  }

  &__menu {
    position: absolute;
    top: calc(1px + 100%);
    left: 0;
    z-index: 1;
    width: 100%;
    background-color: transparent;
  }

  &__list {
    max-height: 400px;
    margin: 0;
    padding: 0;
    background-color: white;
    border-radius: 0.25rem;
    list-style: none;
    overflow: auto;
    overflow-x: overlay;
    box-shadow: 0 10px 20px rgb(31 34 39 / 20%);
  }

  &__item {
    padding: 0.875rem 1rem;
    font-size: calc(var(--font-base) * 0.875);
    background-color: $white;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid $blue-border;
    }

    &:hover {
      background-color: $select-hover;
    }
  }

  &__disabled {
    background-color: $input-disabled-bg;
  }

  &__error {
    position: absolute;
    display: block;
    padding-bottom: 8px;
    font-size: calc(var(--font-base) * 0.8);
    color: $danger;
    letter-spacing: calc(var(--letter-spacing) * 0.5);
  }
}
