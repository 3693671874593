// Override for "~bootstrap/scss/{functions,variables,mixins}"
$gutters: 16px;
$grid-columns: 12;
$grid-gutter-width: 16px;
$grid-row-columns: 6;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px
);
$container-max-widths: (
  sm: 528px,
  md: 672px,
  lg: 960px,
  xl: 1248px
);

.container {
  @media (max-width: map-get($grid-breakpoints, sm)) {
    padding: 0 16px;
  }
}

$font-family-sans-serif: 'Open Sans', sans-serif;
$font-family-base: 'Open Sans', sans-serif;
$font-family-heading: muller, arial, sans-serif;
$base-size: 8px;

@each $i in $grid-breakpoints {
  @for $j from 0 through 7 {
    @if  #{nth($i, 2)} != '0' {
      .mt-#{nth($i, 1)}-#{$j} {
        @media (min-width: #{nth($i, 2)}) {
          margin-top: $base-size * $j !important;
        }
      }
    }

    @else {
      .mt-#{$j} {
        margin-top: $base-size * $j !important;
      }
    }
  }

  @for $j from 0 through 7 {
    @if  #{nth($i, 2)} != '0' {
      .mb-#{nth($i, 1)}-#{$j} {
        @media (min-width: #{nth($i, 2)}) {
          margin-bottom: $base-size * $j !important;
        }
      }
    }

    @else {
      .mb-#{$j} {
        margin-bottom: $base-size * $j !important;
      }
    }
  }
}

// Colors
$primary: #3c4350;
$secondary: #d4d6d7;
$secondary-dark: #a4a6a6;
$danger: #f21f5b;
$accent: #3d46e8;
$accent-main-light: #d6dcff;
$content: #2e3542;
$content-light: #6a6a6a;
$white: #fff;
$placeholder: #c1c3c4;
$accent: #3d46e8;
$bg-dark: #f2f4f8;
$content-dark: #000;
$placeholder-light: #c4c4c4;
$footer: #2d123d;
$footer-hover: #5200ff;
$primary-dark: #553986;
$additional-color-5: #ff8400;
$additional-color-6: #acacac;
$additional-color-7: #e8dfdf;
$additional-color-8: #3f1a57;
$additional-color-9: #f7ebff;
$additional-color-10: #fff4e5;
$additional-color-11: #eaeffc;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'danger': $danger,
);

// Forms

$input-placeholder-color: $placeholder;
$input-color: $content;
$input-border-color: $secondary;
$input-font-size: 14px;
$input-focus-box-shadow: transparent;
$input-focus-border-color: #8ca2d8;
$input-hover-border-color: $secondary-dark;
$input-border-color: $secondary;
$input-focus-width: 0;
$input-disabled-bg: #f4f4f4;

// Typography

$headings-font-family: 'Muller', sans-serif;
$headings-font-weight: bold;
$btn-padding-y: 13px 24px;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * .875 !default;
$font-size-lg: $font-size-base;
$transition-base: all .3s ease-in-out !default;
