.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.main {
    flex: 1;
    min-height: 80vh;
    padding: 20px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 20px;
}

.title {
    text-align: center;
}

.controls {
    display: flex;
    gap: 20px;
}