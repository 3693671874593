@import "styles/common";

.link {
  &__home {
    margin-bottom: 2px;
    padding: 7px 16px;
    font-size: 14px;
    color: $graphite;

    svg {
      transform: rotate(90deg);
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin: 32px 0 16px;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    color: $graphite-text;

    &_icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      fill: $graphite-text;
    }
  }
}

.list {
  margin-bottom: 16px;
  padding-left: 24px;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-main-sidebar;
  background-color: rgba($graphite, 0);

  @include transition($transition-base);

  &_active {
    width: 100%;
    height: 100%;
    background-color: rgba($graphite, 0.5);
  }
}

.modal {
  position: absolute;
  top: 0;
  right: 100%;
  width: 50%;
  height: 100%;
  background: $modal-background;
  overflow: auto;

  @include transition($transition-base);

  @include media-breakpoint-down(sm) {
    width: 59%;
  }

  @include media-breakpoint-down(xs) {
    width: 80%;
  }

  &_active {
    right: 51%;

    @include transition($transition-base);

    @include media-breakpoint-down(sm) {
      right: 41%;
    }

    @include media-breakpoint-down(xs) {
      right: 20%;
    }
  }

  &__menu {
    position: fixed;
    top: 110px;
    left: 0;
    z-index: 1;
    display: none;
    width: 40px;
    height: 40px;
    background-color: $graphite;
    border-radius: 0 8px 8px 0;
    cursor: pointer;

    &_accessible {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_open {
      left: 49%;
      background-color: $modal-background;

      svg {
        width: 22px;
        height: 22px;
        fill: $graphite;
      }

      @include media-breakpoint-down(sm) {
        left: 59%;
      }

      @include media-breakpoint-down(xs) {
        left: 80%;
      }
    }

    &_closed {
      svg {
        width: 22px;
        height: 22px;
        fill: $white;
      }
    }

    @include transition($transition-base);

    @include media-breakpoint-down(xs) {
      top: 78px;
      width: 25px;
      height: 25px;
    }

    @include media-breakpoint-down(sm) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__sidebar {
    width: 280px;
    margin-top: 24px;
    margin-left: calc((100vw - 640px) / 2);

    &_accessible {
      margin-top: 183px;
    }

    @include media-breakpoint-down(sm) {
      margin-left: calc((100vw - 496px) / 2);
    }

    @include media-breakpoint-down(xs) {
      width: 80%;
      margin-top: 24px;
      margin-left: 10%;
    }
  }
}
