@import 'styles/common';

.layout {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: $gradient;

  @include media-breakpoint-down(sm) {
    background: url('/images/iphone-fallback-background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
}

.form {
  flex-grow: 1;
  padding: 40px 0 0;
  background: $modal-background;

  @include media-breakpoint-down(sm) {
    margin-top: 70px;
  }

  @include media-breakpoint-down(xs) {
    padding: 32px 0 0;
  }

  &_main {
    flex-grow: 1;
    padding: 0;

    @include media-breakpoint-down(sm) {
      margin-top: 118px;
    }

    @include media-breakpoint-down(xs) {
      margin-top: 94px;
    }
  }
}

.accessible {
  background: $white;
}
