@import "styles/common";

.border {
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background: url("/images/symbols/dashed.svg") repeat-x;

  &_biggerMargin {
    margin: 40px 0;

    @include media-breakpoint-down(xs) {
      margin: 32px 0;
    }
  }

  &_noMargin {
    margin: 0;
  }

  &_dark {
    background: $gray-border;
    -webkit-mask-image: url("/images/symbols/dashed.svg");
  }

  &_statement {
    margin: 12px;
    background: $gray-border;
    -webkit-mask-image: url("/images/symbols/dashed.svg");
  }
}
