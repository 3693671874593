@import "styles/common";

.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-main-content-modal;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: rgba($graphite, 0.5);
  overflow-y: auto;

  @include media-breakpoint-down(xs) {
    padding: 16px 0;
  }
}

.modal {
  position: relative;
  max-width: min(520px, calc(100vw - 1em * 2));
  width: 100%;
  margin: auto;
  padding: 64px 24px;
  text-align: center;
  background-color: $modal-background;
  border-radius: 8px;

  &_wide {
    min-width: 320px;
    max-width: 600px;

    @include media-breakpoint-down(md) {
      max-width: 510px;
    }
  }

  &__flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    cursor: pointer;
  }
}

@include media-breakpoint-down(xs) {
  .modal {
    width: 320px;
    padding: 64px 16px;
  }
}