@mixin pseudo-element($content) {
  position: absolute;
  content: $content;
  display: block;
}

@mixin accessible-header-icon($background, $bottom) {
  position: absolute;
  right: calc((100% - 11px) / 2);
  bottom: $bottom;
  content: '';
  width: 10px;
  height: 10px;
  background-color: $background;
  border-right: 1px solid $content;
  border-bottom: 1px solid $content;
  transform: rotate(45deg);
}

@mixin mq($width, $type: max) {
  @if map_has_key($grid-breakpoints, $width) {
    $width: map_get($grid-breakpoints, $width);

    @if $type == max {
      $width: $width;
    }

    @media (#{$type}-width: $width) {
      @content;
    }
  }
}
