@import "styles/common";

.checkbox {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    &__control {
        display: block;
        min-width: 16px;
        width: 16px;
        height: 16px;
        border: 1px solid #d4d6d7;
        border-radius: 4px;

        &_active {
            background-color: #3c4350;
            border-color: #3c4350;
            background-size: 100%;

            &::before {
                content: "\2713";
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                font-size: 12px;
                color: white;
            }
        }
    }

    &__text {
        font-weight: normal;
        font-size: var(--font-base);
        font-family: var(--font-family-base);
        letter-spacing: var(--letter-spacing);
        user-select: none;
    }
}