@import 'styles/common';

.information {
  position: absolute;
  top: 110px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $white;

  &_accessible {
    top: 180px;
    color: $content;
  }

  &_burger {
    position: relative;
    top: 0;
    display: block;
  }

  &__photo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    margin-right: 24px;
    background: $gradient;
    border-radius: 100%;

    &_accessible {
      background: $white;
    }

    &_burger {
      width: 56px;
      height: 56px;
      margin: 24px 0 10px;
    }

    &__letter {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      margin: 5px 0 0;
      font-weight: 600;
      font-size: 40px;
      line-height: 1;
      font-family: $font-family-heading;
      color: $graphite;
      letter-spacing: 0;

      &_burger {
        margin: 2px 0 0;
        font-size: 28px;
      }
    }

    &__avatarSkeleton {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }

  &__role {
    display: inline-flex;
    align-items: center;
    color: $dark-gray;
    cursor: pointer;

    &:hover {
      color: $white;

      svg {
        fill: $white;
      }
    }

    svg {
      width: 12px;
      height: 12px;
      margin-left: 7px;
      fill: $dark-gray;

      @include transition($transition-base);
    }

    &_accessible:hover {
      color: $link;
      background: $accessible-blue-background;

      svg {
        fill: $link;
      }
    }

    &_burger:hover {
      color: $dark-gray;

      svg {
        fill: $dark-gray;
      }
    }

    &_burger_active {
      svg {
        transform: rotate(180deg);
      }
    }

    &_active {
      color: $white;

      svg {
        transform: rotate(180deg);
        fill: $white;
      }
    }

    @include transition($transition-base);
  }

  &__name {
    margin: 0;
    font-size: 40px;
    font-family: $font-family-heading;
    letter-spacing: 0;

    &_burger {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0;
    }
  }

  &__degree {
    margin: 0;
    font-size: 16px;
    font-family: $font-family-base;
    text-transform: uppercase;
    letter-spacing: 0;

    &_accessible {
      color: $content;
    }

    &_burger {
      font-size: 14px;
      text-transform: none;
      letter-spacing: 0;
    }
  }
}
