@import "styles/common";

.list {
  &__item {
    margin-top: 16px;
    font-size: 14px;
    line-height: 19px;
    color: $content-light;
    list-style: none;

    a:hover {
      text-decoration: none;
      color: $content;
    }

    &[data-mode="active"] {
      font-weight: 600;
      color: $content;
      list-style: disc;
      pointer-events: none;
    }

    &[data-mode="complete"] {
      position: relative;

      &::before {
        position: absolute;
        left: -20px;
        content: url("/images/symbols/complite.svg");
      }
    }

    &[data-mode="accepted"] {
      position: relative;

      &::before {
        position: absolute;
        left: -20px;
        content: url("/images/symbols/accepted.svg");
        background-size: 30%;
      }
    }

    &[data-mode="error"] {
      position: relative;

      &::before {
        position: absolute;
        left: -20px;
        content: url("/images/symbols/hasErrors.svg");
        background-size: 30%;
      }
    }
  }
}
