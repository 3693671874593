.container {
  position: absolute;
  z-index: 10000;
  display: grid;
  inset: 0;
  backdrop-filter: blur(2px);
  place-content: center;

  .loader {
    width: 5rem;
    background: var(--c) 0% 50%, var(--c) 50% 50%, var(--c) 100% 50%;
    aspect-ratio: 0.75;
    background-size: 20% 50%;
    animation: loading 1s infinite linear;

    --c: no-repeat linear-gradient(#2e3542 0 0);
  }

  @keyframes loading {
    20% {
      background-position: 0% 0%, 50% 50%, 100% 50%;
    }

    40% {
      background-position: 0% 100%, 50% 0%, 100% 50%;
    }

    60% {
      background-position: 0% 50%, 50% 100%, 100% 0%;
    }

    80% {
      background-position: 0% 50%, 50% 50%, 100% 100%;
    }
  }
}
