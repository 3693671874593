@import 'styles/common';

.uploader {
  display: flex;
  flex-direction: column;
  gap: 4px;

      
  &_underlined {
    label {
      text-decoration: underline;
    }

    a {
      text-decoration: underline;
    }
  }

  &__label {
    margin-bottom: 4px;
    font-weight: 600;
    font-size: calc(var(--font-base) * .875);
  }

  &__status {
    width: 100%;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  &__hidden {
    display: none;
  }

  &__placeholder {
    font-weight: 400;
    font-size: calc(var(--font-base) * .875);
    color: $graphite-text;
  }

  &__hint {
    color: $gray-placeholder;
  }

  &__icon {
    width: 16px;
    height: 16px;

    &_file {
      fill: $graphite-text;
    }

    &_del {
      fill: $gray-placeholder;

      &:hover, &:focus {
        fill: $gray-delete;
      }
    }
  }

  &__delete {
    display: flex;
    height: 16px;
    margin-top: -2px;
    padding: 0;
    background: none;
    border: none;
  }

  &__error {
    font-size: calc(var(--font-base) * .8);
    text-decoration: none !important;
    color: $danger;
    cursor: default;
  }
}
