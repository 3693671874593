@import 'styles/common';

.rolesModal {
  position: absolute;
  z-index: 30;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 107px;
  margin-top: 5px;
  background: $white;
  border-radius: 5px;
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 34px;
    border-radius: 4px;
    cursor: default;

    &:not(.rolesModal__item_active) {
      pointer-events: none;
    }

    p {
      margin: 0;
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;
      color: $content;
      letter-spacing: .02em;
    }

    &_active:hover {
      background: $secondary;
      cursor: pointer;
    }
  }
}

.rolesBurger {
  &__item {
    display: flex;
    cursor: pointer;

    p {
      margin: 24px 0 0;
      font-size: 16px;
      font-family: $font-family-heading;
      background: $gradient;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.modal {
  display: flex;
  flex-direction: column;

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
}
