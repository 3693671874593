$app-colors: ();

// Colors

$btn-primary: #5200ff;
$btn-outline: #d4d6d7;
$hint: #a3a7ad;
$white: #fff;
$mask-background: rgb(255 255 255 / 60%);
$black: #000;
$yellow: #fff4cf;
$dark-cream: #e1c770;
$gray: #d1d4d8;
$gray-text: #585d67;
$error-backgrounf-color: #FFE2E2;
$error-border-color: #FFB0B0;
$success-background-color: #90ee90;
$success-border-color: #28a745;
$gray-placeholder: #8e9196;
$gray-delete: #6A6A6A;
$gray-border: #4f5661;
$dark-gray: #858a94;
$gray-footer: #c4c7ca;
$border-grey: #eaeaea;
$border-ligth-grey: #eaebec;
$table-manager: #e7fce4;
$pagination-text: #151717;
$additional-text: #151616;
$pagination-icon: #89969f;
$modal-background: #f2f5f7;
$filter-icon: #686868;
$graphite: #3c4350;
$graphite-text: #2e3542;
$graphite-border: #dfdfdf;
$border-tag: #b9bfca;
$link: #2f3fda;
$link-hovered: #081168;
$link-visited: #828ce9;
$accessible-blue-text: #063462;
$accessible-blue-background: #9dd1ff;
$red: #f00;
$violet-dark: #2d123d;
$blue-border: #e2e4e6;
$select-hover: #ebeef0;
$scroll: #a6a8aa;
$gradient: radial-gradient(76.02% 140.94% at 115.94% 18.42%, rgb(252 166 167 / 50%) 0%, rgb(252 166 167 / 0%) 100%), radial-gradient(65.63% 128.2% at 10.86% 99.05%, rgb(108 181 253 / 50%) 0%, rgb(226 240 248 / 0%) 100%, rgb(108 181 253 / 0%) 100%), #fff;
$gradient-linear: linear-gradient(263.42deg, rgb(252 166 167 / 55%) -14.67%, rgb(252 166 167 / 26.6%) 18.67%, rgb(252 166 167 / 0%) 50%), linear-gradient(84.12deg, rgb(108 181 253 / 45%) 4.67%, rgb(149 202 251 / 29.3%) 23.94%, rgb(226 240 248 / 0%) 59.89%, rgb(108 181 253 / 0%) 59.89%), #fff;

//  Layers

$z-index-header: 10;
$z-index-main-sidebar: 15;
$z-index-main-content: 5;
$z-index-main-content-modal: 500;
$z-index-footer: 0;

// Window' apllications statuses

$application-status-updated: #fff0db;
$application-status-updated-legend: #ffd394;
$application-status-updated-text: #734910;
$application-status-moderating: #edeff2;
$application-status-moderating-legend: #9ea0a3;
$application-status-moderating-text: #2e3542;
$application-status-success: #e2fade;
$application-status-success-legend: #aeffa0;
$application-status-success-text: #206517;
$application-status-failure: #ffe8f0;
$application-status-failure-legend: #ffacc9;
$application-status-failure-text: #86163b;
