.wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    gap: 32px;
}

.row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
}

.field {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__label {
        max-width: 200px;
        height: 16px;
    }
}