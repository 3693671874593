@import 'styles/common';

.link {
  display: flex;
  align-items: center;
  font-size: calc(var(--font-base) * .875);
  line-height: 19px;
  font-family: var(--font-family-base);
  color: $link;
  text-decoration-line: underline;

  &:hover {
    color: $link-hovered;

    svg {
      fill: $link-hovered;
    }
  }

  &:visited {
    color: $link-visited;

    svg {
      fill: $link-visited;
    }
  }

  svg {
    fill: $link;
  }

  &_inline {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: $link;

    &:hover {
      color: $link-hovered;

      svg {
        fill: $link-hovered;
      }
    }

    &:visited {
      color: $link-visited;

      svg {
        fill: $link-visited;
      }
    }

    svg {
      fill: $link;
    }
  }

  &_policies {
    text-decoration: underline;
    color: $link;

    &:hover {
      color: $link-hovered;
    }
  }

  &_simple {
    font-size: calc(var(--font-base) * .875);
    color: $graphite-text;
    text-decoration-line: underline;

    &:hover {
      color: $link;
    }
  }

  &_footer {
    text-decoration-line: none;

    &:hover {
      color: $white;
    }
  }

  &_accessible {
    color: $link;

    &:hover {
      color: $link;
      background: $accessible-blue-background;
      text-decoration-line: none;
    }
  }
}

.icon {
  width: calc(var(--font-base) * .75);
  height: calc(var(--font-base) * .8125);
  margin-left: 4px;
}
